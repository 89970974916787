.App {
  font-family: sans-serif;
  /* text-align: center; */
}

label {
  min-width: 130px;
  display: inline-block;
}

body {
  font-size: 1.8rem;
  line-height: 1.618;
  max-width: 100%;
  margin: auto;
  color: #4a4a4a;
  background-color: #f9f9f9;
  padding: 13px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th, td {
  min-width: 50px;
  border: 1px solid #f1f1f1;
}

.error {
  color: red;
}


aside {
  position: fixed;
  background: #f9f9f9;
  left: 0;
  top: 0;
  padding: 20px;
  width: 400px;
  max-width: 100%;
  box-sizing: border-box;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.data {
  margin-left: 400px;
}